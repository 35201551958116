@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
@font-face {
    font-family: "Roboto-Regular";
    src: local("Roboto-Regular"), url(/static/media/Roboto-Regular.31012f9b.woff) format("woff");
}

@font-face {
    font-family: "Roboto-Bold";
    src: local("Roboto-Bold"), url(/static/media/Roboto-Bold.3f2cbde6.woff) format("woff");
}

@font-face {
    font-family: "Roboto-Italic";
    src: local("Roboto-Italic"), url(/static/media/Roboto-Italic.0d0bf30e.woff) format("woff");
}

@font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"), url(/static/media/Roboto-Medium.e2d3fd03.woff) format("woff");
}

@font-face {
    font-family: "icons";
    src: url(/static/media/icons.6f1ffa56.eot);
    src: url(/static/media/icons.6f1ffa56.eot#iefix) format("embedded-opentype"),
        url(/static/media/icons.18948bfa.ttf) format("truetype"),
        url(/static/media/icons.f094d7d4.woff) format("woff"),
        url(/static/media/icons.73d323ac.svg#icons) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "PoppinsRegular";
    src: url(/static/media/Poppins-Regular.5a421d44.eot);
    src: url(/static/media/Poppins-Regular.5a421d44.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Poppins-Regular.30909621.woff2) format("woff2"),
        url(/static/media/Poppins-Regular.a6e545bf.woff) format("woff"),
        url(/static/media/Poppins-Regular.b6c5fdde.ttf) format("truetype"),
        url(/static/media/Poppins-Regular.3383485f.svg#Poppins-Regular) format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsItalic";
    src: url(/static/media/Poppins-Italic.18dfcb87.eot);
    src: url(/static/media/Poppins-Italic.18dfcb87.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Poppins-Italic.e358f13a.woff2) format("woff2"),
        url(/static/media/Poppins-Italic.14f6efb0.woff) format("woff"),
        url(/static/media/Poppins-Italic.143decd7.ttf) format("truetype"),
        url(/static/media/Poppins-Italic.4bc353a6.svg#Poppins-Italic) format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url(/static/media/Poppins-SemiBold.ca03f488.eot);
    src: url(/static/media/Poppins-SemiBold.ca03f488.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Poppins-SemiBold.4484b47c.woff2) format("woff2"),
        url(/static/media/Poppins-SemiBold.cd7415c2.woff) format("woff"),
        url(/static/media/Poppins-SemiBold.4e60d79a.ttf) format("truetype"),
        url(/static/media/Poppins-SemiBold.24b955c0.svg#Poppins-SemiBold) format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url(/static/media/Poppins-Bold.ddb3dcd1.eot);
    src: url(/static/media/Poppins-Bold.ddb3dcd1.eot?#iefix) format("embedded-opentype"),
        url(/static/media/Poppins-Bold.67636336.woff2) format("woff2"),
        url(/static/media/Poppins-Bold.5342fdbc.woff) format("woff"),
        url(/static/media/Poppins-Bold.aae80503.ttf) format("truetype"),
        url(/static/media/Poppins-Bold.80260454.svg#Poppins-Bold) format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

[class^="custom-icon-"],
[class*=" custom-icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "icons" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.custom-icon-facebook:before {
    content: "\e900";
    color: #9f9f9f;
}
.custom-icon-github:before {
    content: "\e901";
    color: #9f9f9f;
}
.custom-icon-telegram:before {
    content: "\e902";
    color: #d01f36;
}
.custom-icon-twitter:before {
    content: "\e903";
    color: #9f9f9f;
}

body {
    margin: 0;
    font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    font-size: 14px;
    margin: 0px;
}

main {
    background-color: #f7f8fa;
}

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

a,
a:hover,
a:active {
    text-decoration: none;
    color: #4a4a4a;
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

ol,
ul {
    list-style: none;
    padding: 0px;
    margin-left: 0px;
}

input[type="text"][disabled],
input:disabled {
    cursor: not-allowed;
}

.text-danger {
    color: red;
}

.text-center {
    text-align: center;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-lowercase {
    text-transform: lowercase;
}

input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

button:disabled {
    /* khong important file commont */
    /* background-color: #5d5d5d !important; */
    /* border-color: #5d5d5d !important; */
    background-color: #5d5d5d;
    border-color: #5d5d5d;
    cursor: not-allowed;
}

.collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

body > iframe {
    pointer-events: none;
}

.MuiMenu-paper {
    background-color: rgba(57, 58, 85, 0.85) !important;
}

.MuiMenuItem-root {
    padding: 15px 22px 0px 22px !important;
    font-size: 14 !important;
    color: rgba(255, 255, 255, 0.7) !important;
}

.rbc-overlay {
    z-index: 1000 !important;
}

html *::-webkit-scrollbar {
    border-radius: 4px;
    width: 10px;
    height: 4px;
}
html *::-webkit-scrollbar {
    border-radius: 4px;
    width: 10px;
    height: 4px;
}
html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9c9da9;
}
html *::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9c9da9;
}
html *::-webkit-scrollbar-track {
    border-radius: 0%;
    background-color: #393b53;
}
html *::-webkit-scrollbar-track {
    border-radius: 0%;
    background-color: #393b53;
}

.style_loader__1qEVD {
    border: 8px solid #3ac3e2;
    border-radius: 50%;
    border-top: 8px solid rgba(196, 196, 196, 0.2);
    width: 60px;
    height: 60px;
    -webkit-animation: style_spin__2kwgc 2s linear infinite;
    animation: style_spin__2kwgc 2s linear infinite;
    margin: 0px auto;
    position: relative;
}

/* Safari */
@-webkit-keyframes style_spin__2kwgc {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes style_spin__2kwgc {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.style_loader__nJ4RJ {
    border: 8px solid #3ac3e2;
    border-radius: 50%;
    border-top: 8px solid rgba(196, 196, 196, 0.2);
    width: 60px;
    height: 60px;
    -webkit-animation: style_spin__30l1Z 2s linear infinite;
    animation: style_spin__30l1Z 2s linear infinite;
    margin: 0px auto;
    position: relative;
}

/* Safari */
@-webkit-keyframes style_spin__30l1Z {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes style_spin__30l1Z {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

